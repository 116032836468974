_<template>
    <PopupWrpr @close="close">
        <div class="popup__window-content">
            <div class="popup__window-header mb-30">
                <h2>{{ view ? $translate('view') : $translate('add') }} {{ $translate('smart-condition') }}</h2>
                <button type="button" class="popup__closer" @click="close">
                    <inline-svg :src="require('@/assets/img/close-icon.svg')"/>
                </button>
            </div>
            <div class="popup__window-body">
                <form action="#" :style="{ pointerEvents: !view ? 'initial': 'none' }">
                    <div class="form-group">
                        <div class="form-group__row flex-sm-wrap">
                            <div class="form-group__col-5 form-group__col">
                                <div class="el-form">
                                    <label class="el-form__title">{{ $translate('select-batch-id') }}</label>
                                    <DropdownAdvanced
                                        v-if="!view"
                                        :options="bathcesOptions"
                                        v-model="config.select_batch_id"
                                    />
                                    <input 
                                        type="text" class="input" placeholder="Batch ID"
                                        v-else v-model="condition.batch.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group__col-5 form-group__col">
                                <div class="el-form">
                                    <label class="el-form__title">{{ $translate('select-device') }}</label>
                                    <DropdownAdvanced
                                        v-if="!view" :options="devices"
                                        v-model="config.select_device"
                                        @change="generateParameterObeserved"
                                    />
                                    <input 
                                        type="text" class="input" :placeholder="$translate('device')"
                                        v-else-if="devices.find(item => item.id === config.select_device)" :value="devices.find(item => item.id === config.select_device).name"
                                    />
                                </div>
                            </div>
                        </div>
                        <hr class="line-hr my-25" />
                        <div class="form-group__row flex-sm-wrap">
                            <div class="form-group__col-5 form-group__col">
                                <div class="el-form">
                                    <label class="el-form__title">Which parameter would you like to monitor?</label>
                                    <DropdownAdvanced
                                        v-if="!view"
                                        :options="parameterObeservedOptions"
                                        v-model="config.parameter_obeserved"
                                    />
                                    <input 
                                        v-else type="text" class="input"
                                        :value="config.parameter_obeserved" style="text-transform: capitalize;pointer-events: none;"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="form-group__row flex-sm-wrap">
                            <div class="form-group__col-5 form-group__col">
                                <div class="el-form">
                                    <label class="el-form__title">What is the target value that you want to set?</label>
                                    <input type="text" class="input" :placeholder="$translate('enter-number...')" v-model="config.value" />
                                </div>
                            </div>
                            <div class="form-group__col-5 form-group__col">
                                <div class="el-form">
                                    <label class="el-form__title">How do you want to set your target value?</label>
                                    <DropdownAdvanced
                                        :options="operators"
                                        v-model="config.operator"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="form-group__row flex-sm-wrap">
                            <div class="form-group__col-5 form-group__col">
                                <div class="el-form">
                                    <label class="el-form__title">{{ $translate('from-date') }}</label>
                                    <DatePicker v-model="config.from_date"></DatePicker>
                                </div>
                            </div>
                            <div class="form-group__col-5 form-group__col">
                                <div class="el-form">
                                    <label class="el-form__title">{{ $translate('from-time') }}</label>
                                    <TimePicker v-model="config.from_time"></TimePicker>
                                </div>
                            </div>
                        </div>
                        <div class="form-group__row flex-sm-wrap">
                            <div class="form-group__col-5 form-group__col">
                                <div class="el-form">
                                    <label class="el-form__title">{{ $translate('to-date') }}</label>
                                    <DatePicker v-model="config.to_date"></DatePicker>
                                </div>
                            </div>
                            <div class="form-group__col-5 form-group__col">
                                <div class="el-form">
                                    <label class="el-form__title">{{ $translate('to-time') }}</label>
                                    <TimePicker v-model="config.to_time"></TimePicker>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div style="padding: 20px 0 0;display: flex;align-items: center;justify-content: center;" v-if="chartDataLoading && view">
                    <Spinner class="spinner_size_70" />
                </div>
                <div v-if="deviceChartData && !chartDataLoading">
                    <div class="card__header style-2" style="color: #9DB0B7;padding: 0 0 10px;margin: 20px 0 10px;">
                        {{ devices.find(itemD => itemD.id === config.select_device).name }}:
                    </div>
                    <MeasurmentsChart 
                        :chartData="deviceChartData"
                        :category="devices.find(itemD => itemD.id === config.select_device).category" :dateSort="dateSort" style="padding: 0;border: 0;border-width: 0;"
                        :chartId="`chart-${config.select_device}`" :dataFontSize="10"
                    />
                </div>
            </div>
            <div class="popup__window-actions">
                <button 
                    type="button"
                    class="btn btn-w-shadow btn-standart-2 popup__closer"
                    @click="close"
                >
                    <span>{{ $translate('cancel') }}</span>
                </button>
                <button
                    @click="submit" type="submit"
                    class="btn btn-primary btn-standart-2" v-if="!view"
                >
                    <span>{{ $translate('add-smart-condition') }}</span>
                </button>
            </div>
        </div>
    </PopupWrpr>
</template>

<script>
import Base from "@/components/base";
import {operators} from "@/config/dispatch";
import PopupWrpr from './PopupWrpr';
import MeasurmentsChart from "@/components/charts/MeasurmentsChart";
import { devCats } from '@/config';

export default {
    name: "AddSmartCondition",
	components: {
		...Base,
        PopupWrpr,
        MeasurmentsChart,
	},
    props: ["batches", "devices", 'condition', 'view', 'deviceDataDuringSC'],

    data() {
        return {
            config:{
                select_batch_id:null,
                select_device:null,
                parameter_obeserved:null,
                operator:null,
                value:null,
                from_date:null,
                from_time:null,
                to_date:null,
                to_time:null
            },
            parameterObeservedOptions: [],
            deviceChartData: null,
            dateSort: "Year",
            chartDateViews: [ "Day", "Month", "Year", ],
            devCats: devCats,
            chartDataLoading: true,
        };
    },
    computed:{
        operators(){
            return Object.entries(operators).map(([id,name])=>{
                return {
                    id,name
                }
            })
        },
        bathcesOptions(){
            return [...this.batches,{id:"all",name:"All"}]
        },
    },
    methods: {
        async generateParameterObeserved(e){
            let findedDevice = e.target.value;
            console.log('findedDevice:', findedDevice);
            this.config.parameter_obeserved = null;

            if(findedDevice.config.device_id){
                this.parameterObeservedOptions = [];

                if(findedDevice.category === 'carbon-dioxide' || findedDevice.category === 'phosphine') {
                    let deviceDataFromApi = await this.$provider.centaurAPI.getRealDeviceData(findedDevice.config.device_id);

                    for (let index = 0; index < deviceDataFromApi.length; index++) {
                        let devCatsFinded = devCats.find(dItem => dItem.centaur_type_id === deviceDataFromApi[index].type);
                        
                        if(devCatsFinded && !this.parameterObeservedOptions.map(item => item.id).includes(devCatsFinded.id)){
                            this.parameterObeservedOptions.push({
                                name: devCatsFinded.name,
                                id: devCatsFinded.id
                            });
                        }
                    }
                } else {
                    let parameters = [];

                    if(findedDevice.category === 'temperature'){
                        parameters = [
                            ['Temperature', 'temperature']
                        ];
					}

					if(findedDevice.category === 'leaf-moisture'){
                        parameters = [
                            ['Leaf moisture', 'leaf-moisture']
                        ];
					}

					if(findedDevice.category === 'soil-moisture'){
                        parameters = [
                            ['Soil moisture', 'soil-moisture']
                        ];
					}

                    if(findedDevice.category === 'temperature-humidity'){
                        parameters = [
                            ['Temperature', 'temperature'],
                            ['Dew point temp', 'dew-point-temp'],
                            ['Air humidity', 'air-humidity']
                        ];
                    }

                    if(findedDevice.category === 'weather-station-xsense'){
                        parameters = [
                            ['Temperature', 'temperature'],
                            ['Dew point temp', 'dew-point-temp'],
                            ['Air humidity', 'air-humidity'],
                            ['Rain Meter', 'rain-meter'],
                            ['Average wind speed', 'average-wind-speed'],
                            ['Max wind speed', 'max-wind-speed'],
                            ['Solar Radiation', 'solar-radiation']
                        ];
                    }

                    for (let index = 0; index < parameters.length; index++) {
                        this.parameterObeservedOptions.push({
                            name: parameters[index][0],
                            id: parameters[index][1]
                        });
                    }
                }
            }
        },
        close() {
            this.$emit("close");
        },
        changeConfig(ev) {
            const { value, name } = ev.target;
            this.config[name] = value;
        },
        submit(){
            this.config.condition_type = "smart";

            if(this.condition?.id){
                this.config.id=this.condition.id
                this.$emit('submit', this.config, true);
                return 
            }
            this.config.id=Date.now()
			this.$emit('submit', this.config);
		},
        async smartConditionChart(){
            if(this.deviceDataDuringSC && this.config.select_device){
                let splitFromDate = this.config.from_date.split('.');
                let splitToDate = this.config.to_date.split('.');

                let findDeviceItem = this.devices.find(itemD => itemD.id === this.config.select_device);

                if(findDeviceItem.category === 'carbon-dioxide' || findDeviceItem.category === 'phosphine') {
                    const getedRealDeviceDataThere = await this.$provider.centaurAPI.getRealDeviceData(
                        findDeviceItem.config.device_id, { 
                            from: `${ splitFromDate[2]+'-'+splitFromDate[1]+'-'+splitFromDate[0] }T${this.config.from_time}:00.000Z`,
                            to: `${ splitToDate[2]+'-'+splitToDate[1]+'-'+splitToDate[0] }T${this.config.to_time}:00.000Z`
                        }
                    );

                    console.log('1 getedRealDeviceDataThere:', getedRealDeviceDataThere);

                    if(getedRealDeviceDataThere && getedRealDeviceDataThere.length){
                        this.deviceChartData = getedRealDeviceDataThere;
                    }
                } else {
                    let timeDiff = Math.abs(new Date(`${ splitFromDate[2]+'-'+splitFromDate[1]+'-'+splitFromDate[0] }T${this.config.from_time}:00.000Z`).getTime() - new Date(`${ splitToDate[2]+'-'+splitToDate[1]+'-'+splitToDate[0] }T${this.config.to_time}:00.000Z`).getTime());
                    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                    
                    let fromDate = new Date(`${ splitFromDate[2]+'-'+splitFromDate[1]+'-'+splitFromDate[0] }T${this.config.from_time}:00.000Z`);
                    fromDate.setDate(fromDate.getDate() + diffDays);
                    // console.log('fromDate:', fromDate.toISOString());

                    let getedRealDeviceDataThere = await this.$provider.xFarm.getDeviceData(
                        findDeviceItem.config.device_id, 
                        { from: fromDate.toISOString(), periodTo: `${ diffDays }d`}
                    );

                    console.log('getedRealDeviceDataThere:', fromDate.toISOString(), getedRealDeviceDataThere);

                    // .slice(0, 50).reverse()
                    getedRealDeviceDataThere = getedRealDeviceDataThere.filter(item => new Date(item.time).getTime() < new Date(`${ splitToDate[2]+'-'+splitToDate[1]+'-'+splitToDate[0] }T${this.config.to_time}:00.000Z`).getTime());

                    let valueVariableName = this.devCats.find(item => item.id === this.config.parameter_obeserved).xFarmVN;

                    if(getedRealDeviceDataThere && getedRealDeviceDataThere.length){
                        this.deviceChartData = getedRealDeviceDataThere.map(item => {
                            return {
                                "timestamp": item.time,
                                "type": 'temp',
                                "value": item[valueVariableName],
                                "deviceId": item.did,
                            }
                        });
                    }
                }


                this.chartDataLoading = false;
            }
        }
    },
    created(){
        if(this.condition){
            Object.keys(this.config).forEach(key=>{
                if(this.condition[key]){
                    this.config[key]=this.condition[key]
                }
            })
        }

        if(this.view && this.deviceDataDuringSC){
            this.smartConditionChart();
        }

        setTimeout(() => {
            if(this.deviceChartData === null){
                this.chartDataLoading = false;
            }
        }, 5000);
    },
};
</script>
