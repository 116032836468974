export const operators = {
    'equal': 'Equal',
    'not_equal': 'Not Equal',
    'greater_than': 'Greater Than',
    'less_than': 'Less Than',
    'equal_or_grater_than': 'Equal or Grater Than',
    'equal_or_less_then': 'Equal or Less Than',
}
export const businessConditionTypes = {
    'delivery_time':'Delivery time',
    'certifications':'Certifications',
    'custom':'Custom'

}
export const eventsToBeMonitored={
    delivery_time:{
        'time_range':'Delivery within a time range',
        'date_time':'Delivery within a date/time',
        'custom':'Custom'
    },
    certifications:{
        'presence':'Presence of certifications',
        'custom':'Custom'

    }
}

export const statuses=[
    "Draft",
    "Pending",
    "Approved",
    "Outgoing",
    "Incoming",
    "Other",
    "Completed"
]

export const parseCVSconfig={
    batchNamePosition:0,
    model:{
        batch:[
            {
                position:0,
                fieldName:"name"
            }
        ],
        products:{
            startPosition:1
        }
    },
    colsDevider:",",
    searchByName:true,
    searchByNameReg:{
        start:"",
        end:""
    }
}