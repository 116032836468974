<template>
  	<div class="popup_wrpr" @click.self.stop="close">
		<div class="popup__window" @click.self.stop="close">
            <slot />
		</div>
	</div>	
</template>

<script>
export default {
    name: 'PopupWrpr',
	methods: {
        close() {
            this.$emit('close');
            document.querySelector('body').classList.remove('ovh');
        }
    },
    created() {
		document.querySelector('body').classList.add('ovh');
	},
}
</script>