<template>
    <PopupWrpr @close="close">
        <div class="popup__window-content" v-if="!dontForget">
            <div class="popup__window-header mb-30">
                <h2>{{ view ? $translate('view') : $translate('add') }} {{ $translate('business-condition') }}</h2>
                <button type="button" class="popup__closer" @click="close">
                    <inline-svg :src="require('@/assets/img/close-icon.svg')" />
                </button>
            </div>
            <div class="popup__window-body" :style="{ 'pointer-events': view ? $translate('none') : $translate('initial') }">
                <form action="#">
                    <div class="form-group">
                        <div class="form-group__row flex-sm-wrap">
                            <div class="form-group__col-5 form-group__col">
                                <div class="el-form">
                                    <label class="el-form__title">{{ $translate('select-batch-id') }}</label>
                                    <DropdownAdvanced
                                        v-if="!view"
                                        :options="bathcesOptions"
                                        v-model="config.select_batch_id"
                                    />
                                    <input v-else type="text" class="input" placeholder="Batch ID" :value="config.select_batch_id" />
                                </div>
                            </div>
                        </div>
                        <hr class="line-hr my-25" />


                        <!-- custom type section -->
                        <template v-if="config.type === 'custom'">
                            <div class="form-group__row flex-sm-wrap">
                                <div class="form-group__col-5 form-group__col">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('type') }}</label>
                                        <DropdownAdvanced
                                            :options="businessConditionTypesOptions"
                                            v-model="config.type"
                                            v-if="!view"
                                        />
                                        <input v-else type="text" class="input" placeholder="Type" :value="config.type" />
                                    </div>
                                </div>
                                <div class="form-group__col-5 form-group__col" v-if="config.type">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('type-name') }}</label>
                                        <input type="text" class="input" placeholder="Custom event" v-model="customConfig.type"/>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group__row flex-sm-wrap">
                                <div class="form-group__col-5 form-group__col">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('event-to-be-monitored') }}</label>
                                        <input type="text" class="input" placeholder="Custom event" v-model="customConfig.event_to_be_monitored"/>
                                    </div>
                                </div>
                            </div>
                        </template>
                        
                        <!-- normal type section -->
                        <template v-else>
                            <div class="form-group__row flex-sm-wrap">
                                <div class="form-group__col-5 form-group__col">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('type') }}</label>
                                        <DropdownAdvanced
                                            :options="businessConditionTypesOptions"
                                            v-model="config.type"
                                            v-if="!view"
                                        />
                                        <input 
                                            v-else type="text" class="input" :placeholder="$translate('type')" 
                                            :value="businessConditionTypesOptions.find(itemF => itemF.id === config.type).name" 
                                        />
                                    </div>
                                </div>
                                <div class="form-group__col-5 form-group__col" v-if="eventOptions">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('event-to-be-monitored') }}</label>
                                        <DropdownAdvanced
                                            :options="eventOptions"
                                            v-model="config.event_to_be_monitored"
                                            v-if="!view"
                                        />
                                        <input 
                                            v-else type="text" class="input" :placeholder="$translate('event-to-be-monitored')"
                                            :value="eventOptions.find(itemF => itemF.id === config.event_to_be_monitored).name"
                                        />
                                    </div>
                                </div>

                                <div class="form-group__col-5 form-group__col" v-if="config.event_to_be_monitored === 'custom'" style="margin-top: 10px;">
                                    <div class="el-form">
                                        <label class="el-form__title">Custom event</label>
                                        <input type="text" class="input" placeholder="Custom event" v-model="config.custom_event" />
                                    </div>
                                </div>  
                            </div>
                        </template>
                        <!-- value section -->
                        <template v-if="config.type === 'delivery_time' && config.event_to_be_monitored === 'time_range'">
                            <div class="form-group__row flex-sm-wrap">
                                <div class="form-group__col-5 form-group__col">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('from-date') }}</label>
                                        <DatePicker v-model="time_range_config.from_date"></DatePicker>
                                    </div>
                                </div>
                                <div class="form-group__col-5 form-group__col">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('from-time') }}</label>
                                        <TimePicker v-model="time_range_config.from_time"></TimePicker>

                                    </div>
                                </div>
                            </div>
                            <div class="form-group__row flex-sm-wrap">
                                <div class="form-group__col-5 form-group__col">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('to-date') }}</label>
                                        <DatePicker v-model="time_range_config.to_date"></DatePicker>
                                    </div>
                                </div>
                                <div class="form-group__col-5 form-group__col">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('to-time') }}</label>
                                        <TimePicker v-model="time_range_config.to_time"></TimePicker>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="config.type === 'delivery_time' && (config.event_to_be_monitored === 'date_time' || config.event_to_be_monitored === 'custom')">
                            <div class="form-group__row flex-sm-wrap">
                                <div class="form-group__col-5 form-group__col">
                                    <div class="el-form">
                                        <!-- <label class="el-form__title">{{ $translate('from-date') }}</label> -->
                                        <label class="el-form__title">Within date</label>
                                        <DatePicker v-model="date_time_config.date"></DatePicker>
                                    </div>
                                </div>
                                <div class="form-group__col-5 form-group__col">
                                    <div class="el-form">
                                        <!-- <label class="el-form__title">{{ $translate('from-time') }}</label> -->
                                        <label class="el-form__title">Within time</label>
                                        <TimePicker v-model="date_time_config.time"></TimePicker>
                                    </div>
                                </div>
                            </div>

                        </template>
                        <template v-if="config.type === 'certifications'">
                            <div class="el-form mt-15">
                                <div class="el-form">
                                    <label class="el-form__title">{{ $translate('description') }}</label>
                                    <textarea class="textarea" :placeholder="$translate('description-of-certifications-required...')" v-model="description_of_certifications_required"></textarea>

                                </div>
                            </div>
                        </template>
                        <template v-if="config.type === 'custom'">
                            <div class="el-form mt-15">
                                <div class="el-form">
                                    <label class="el-form__title">{{ $translate('description') }}</label>
                                    <textarea class="textarea" :placeholder="$translate('description-of-certifications-required...')" v-model="description_of_the_business_rule"></textarea>
                                </div>
                            </div>
                                
                        </template>
                        <!-- <div class="form-group__row flex-sm-wrap">
                            <div class="form-group__col-5 form-group__col">
                                <div class="el-form">
                                    <label class="el-form__title">Value</label>
                                    <input type="text" class="input" placeholder="Enter number..." v-model="config.value">
                                </div>
                            </div>
                        </div> -->
                    </div>
                </form>
            </div>
            <div class="popup__window-actions">
                <button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="close">
                    <span>{{ $translate('cancel') }}</span>
                </button>
                <!--   -->
                <button type="submit" class="btn btn-primary btn-standart-2"  @click="dontForget = true" v-if="!view && config.type === 'certifications'">
                    <span>{{ $translate('add-business-condition') }}</span>
                </button>
                <button type="submit" class="btn btn-primary btn-standart-2"  @click="submit" v-else-if="!view">
                    <span>{{ $translate('add-business-condition') }}</span>
                </button>
            </div>
        </div>
        <div class="popup__window-content max-w-475" v-else>
            <div class="popup__window-header mb-30">
                <h2>Don’t forget to attach your document!</h2>
                <button type="button" class="popup__closer" @click="close">
                    <inline-svg :src="require('@/assets/img/close-icon.svg')" />
                </button>
            </div>
            <div class="popup__window-actions">
                <button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="submit">
                    <span>Continue</span>
                </button>
                <button type="submit" class="btn btn-primary btn-standart-2" @click="toDocuments">
                    <span>Edit</span>
                </button>
            </div>
        </div>
    </PopupWrpr>
</template>

<script>
import Base from "@/components/base";
import {businessConditionTypes,eventsToBeMonitored} from "@/config/dispatch";
import PopupWrpr from './PopupWrpr';

export default {
    name: "AddBusinessCondition",
    props: ["batches", "condition", "view"],
    components: {
        ...Base,
        PopupWrpr,
    },
    data() {
        return {
            config: {
                select_batch_id: null,
                type: null,
                event_to_be_monitored: null,
                value: null,
                custom_event: null,
            },
            customConfig:{
                type:null,
                event_to_be_monitored:null
            },
            time_range_config:{
                from_date:null,
                from_time:null,
                to_date:null,
                to_time:null
            },
            date_time_config:{
                date:null,
                time:null
            },
            description_of_certifications_required:null,
            description_of_the_business_rule:null,
            dontForget: false,
        };
    },
    computed:{
        bathcesOptions(){
            return [...this.batches,{id:"all",name:"All"}]
        },
        businessConditionTypesOptions(){
            return Object.entries(businessConditionTypes).map(c=>({id:c[0],name:c[1]}))
        },
        eventOptions(){
            if(this.config.type&&this.config.type!=="custom"){
                return  Object.entries(eventsToBeMonitored[this.config.type]).map(e=>({id:e[0],name:e[1]}))
            } 
            return null
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },
        changeConfig(ev) {
            const { value, name } = ev.target;
            this.config[name] = value;
        },
        async submit() {
            let input={
                ...this.config,
                condition_type:'business'
            }
            if(this.config.type=='delivery_time'&&this.config.event_to_be_monitored=='time_range'){
                input={
                    ...input,
                    ...this.time_range_config
                }
            }
            if(this.config.type=='delivery_time'&&(this.config.event_to_be_monitored=='date_time'||this.config.event_to_be_monitored=='custom')){
                input={
                    ...input,
                    ...this.date_time_config
                }
            }
            if(this.config.type=='certifications'){
                input = {
                    ...input,
                    description_of_certifications_required:this.description_of_certifications_required
                }
            }
            if(this.config.type=='custom'){
                input = {
                    ...input,
                    ...this.customConfig,
                    description_of_the_business_rule:this.description_of_the_business_rule
                }
            }

            console.log("res",input);
            if(this.condition?.id){
                input.id=this.condition.id
                this.$emit('submit', input, true);
                return 
            }
            input.id=Date.now()
			this.$emit('submit',input);
        },
        async toDocuments(){
            await this.submit();

            setTimeout(() => {
               this.$router.push('/create-dispatch?tab=info'); 
            }, 500);
        }
    },
    created(){
        if(this.condition){
            Object.keys(this.config).forEach(key=>{
                if(this.condition[key]){
                    this.config[key]=this.condition[key]
                }
            })
            if(this.condition.type == 'delivery_time'){
                if(this.condition.event_to_be_monitored=='time_range'){
                    this.time_range_config={
                        from_date:this.condition.from_date,
                        from_time:this.condition.from_time,
                        to_date:this.condition.to_date,
                        to_time:this.condition.to_time,                    
                    }
                }
                if(this.condition.event_to_be_monitored=='date_time'||this.condition.event_to_be_monitored=='custom'){
                    this.date_time_config={
                        date:this.condition.date,
                        time:this.condition.time
                    }
                }
            }
            if(this.condition.type=='certifications'){ 
                this.description_of_certifications_required= this.condition.description_of_certifications_required
            }
            if(this.condition.description_of_the_business_rule){
                this.description_of_the_business_rule=this.condition.description_of_the_business_rule
                this.config.type='custom'
                this.customConfig={
                    type:this.condition.type,
                    event_to_be_monitored:this.condition.event_to_be_monitored
                }
            }
        }
    },
};
</script>
